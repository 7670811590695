import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/home',
    name: 'homepage',
    component: () => import('../views/MainView.vue'),
    children: [
      {
        path: '/:any',
        name: 'home',
        component: () => import('../pages/home/IndexView.vue'),
      },
      {
        path: '/:any/payment/:id',
        name: 'payment',
        component: () => import('../pages/home/PaymentView.vue'),
      },
      {
        path: '/:any/payment/:id/thankyou',
        name: 'thankyou',
        component: () => import('../pages/home/ThankyouView.vue'),
      },
    ]
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
